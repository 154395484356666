import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import PreLoader from '../Loading/PreLoader';
import NotFound from '../pages/NotFound';

// lazy loading
const Home = React.lazy(() => import('../pages/Home'));
const ContactUs = React.lazy(() => import('../pages/ContactUs'));
const Blogs = React.lazy(() => import('../pages/Blogs'));
const Portfolio = React.lazy(() => import('../pages/Portfolio'));
const SoftwareDevelopment = React.lazy(() => import('../pages/SoftwareDevelopment'));
const WebsiteDevelopment = React.lazy(() => import('../pages/WebsiteDevelopment'));
const MobileAppDevelopment = React.lazy(() => import('../pages/MobileAppDevelopment'));
const GrapicDesign = React.lazy(() => import('../pages/GrapicDesign'));
const SoftwareTesting = React.lazy(() => import('../pages/SoftwareTesting'));
const DigitalMarketing = React.lazy(() => import('../pages/DigitalMarketing'));
const Career = React.lazy(() => import('../pages/Career'));
const Team = React.lazy(() => import('../pages/Team'));
const AboutUs = React.lazy(() => import('../pages/AboutUs'));


const AppRoutes = () => {
    return (
        <Suspense fallback={<PreLoader />}>
            <Routes>

                <Route index element={<Home />} />

                <Route path="/portfolio" element={<Portfolio />} />

                <Route path="/contactus" element={<ContactUs />} />

                <Route path="/blog_details" element={<Blogs />} />

                <Route path="/softwaredevelopment" element={<SoftwareDevelopment />} />

                <Route path="/websitedevlopent" element={<WebsiteDevelopment />} />

                <Route path="/mobileappdevelopment" element={<MobileAppDevelopment />} />

                <Route path="/graphicdesigning" element={<GrapicDesign />} />

                <Route path="/softwaretesting" element={<SoftwareTesting />} />

                <Route path="/digitalmarketing" element={<DigitalMarketing />} />

                <Route path="/careers" element={<Career />} />

                <Route path="/team" element={<Team />} />

                <Route path="/aboutus" element={<AboutUs />} />

                <Route path="/loading" element={<PreLoader />} />

                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;