import React, { useState } from 'react';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import logo2 from './../../../assets/logo/navy-200.png';
import './Navbar.scss';


const Navbar = ({bg = '#fff'}) => {

    const [toggle, setToggle] = useState(false)
    const [showScroll, setShowScroll] = useState(false)
    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 100) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 100) {
            setShowScroll(false)
        }
    };
    window.addEventListener('scroll', checkScrollTop)

    const togglerClick = () => {
        setToggle(p => !p)
    }

    return (
        <Fragment>
            <div className="header-nav">
                <div style={{backgroundColor: bg}} className={showScroll ? "navigation navbar_sticky" : "navigation"}>
                    <nav className="navbar navbar-expand-lg navbar-light ">
                        <NavLink className="navbar_logo" to="/">
                            <img src={logo2} alt="" />
                        </NavLink>
                      

                        <div className={toggle ? "navbar-collapse sub-menu-bar" : "collapse navbar-collapse sub-menu-bar"} id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="#">COMPANY</NavLink>

                                    <button className="sub-nav-toggler">
                                        <i className="fa fa-angle-down"></i>
                                    </button>
                                    <ul className="sub-menu">
                                        <li>
                                            <NavLink to="/aboutus">ABOUT US</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/team">OUR TEAM</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/careers">CAREERS</NavLink>
                                        </li>

                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="##/">WHAT WE DO</NavLink>
                                    <ul className="sub-menu">
                                        <li>
                                            <NavLink to="/softwaredevelopment">SOFTWARE DEVELOPMENT</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/websitedevlopent">WEBSITE DEVELOPMENT</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/mobileappdevelopment">MOBILE APP DEVELOPMENT</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/graphicdesigning">GRAPHIC DESIGNING</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/softwaretesting">SOFTWARE TESTING</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/digitalmarketing">DIGITAL MARKETING</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/portfolio">PORTFOLIO</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/blog_details">BLOGS</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/contactus">CONTACT US</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="lets_talk_btn mr-100">
                            <NavLink className="main-btn border-1px" to="/contactus">LETS TALK</NavLink>
                        </div>
                          
                        <button className={toggle ? "navbar-toggler active" : "navbar-toggler"} onClick={togglerClick} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="toggler-icon"></span>
                            <span className="toggler-icon"></span>
                            <span className="toggler-icon"></span>
                        </button>
                    </nav>
                </div>
            </div>
            <br /><br /><br /><br />
        </Fragment>
    );
};

export default Navbar;