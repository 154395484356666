import React from 'react';
import './PreLoader.scss';

const PreLoader = () => {
    return (
        <div>
            <div className="preloader">
                <div className="loading">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
        </div>
    );
};

export default PreLoader;