import React from 'react';
import './CopyRight.scss';

const CopyRight = () => {
    return (
        <div>
               <section className="footer-copyright">
                © All Copyright 2022 Reserved by <a href="malito:info@aspiretechnologiez.com"><strong style={{ color: '#b882fc' }}>Aspire Technologiez, Inc.</strong></a>
            </section>
        </div>
    );
};

export default CopyRight;