import { Container, Grid } from '@mui/material';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import logo from './../../../assets/logo/white-80.png';
import CopyRight from './CopyRight';
import './Footer.scss';

const Footer = () => {
    return (
        <Fragment>
            <footer className="footer">
                <div className="footer_div">
                    <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8} lg={3.5}>
                            <div>
                                <img className="" style={{width: '160px', height: '80px', marginTop: '-20px'}} src={logo} alt="logo" />
                                <p>Aspire Technologiez is a customer oriented company offering Mobile applications, Software Development, Web Development , and Business Development Services in various technologies. </p>

                                <div className="footer-about">
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                <i className="fa-brands fa-twitter"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <i className="fa-brands fa-facebook"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <i className="fa-brands fa-linkedin"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <i className="fa-brands fa-instagram"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <i className="fa-brands fa-behance"></i>
                                            </Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/">
                                                <i className="fa-brands fa-youtube"></i>
                                            </Link>
                                        </li> */}
                                    </ul>
                                    <br /> 

                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={4} lg={2.5}>
                            <div className="footer_link_list">
                                <h2 className="title">Aspire Technologiez</h2>
                                <ul>
                                    <li>
                                        <Link to="/aboutus">
                                            <i className="fa-solid fa-angle-right"></i>
                                            ABOUT US</Link>
                                    </li>
                                    <li>
                                        <Link to="/team">
                                            <i className="fa-solid fa-angle-right"></i>
                                            MY TEAM</Link>
                                    </li>

                                </ul>
                                <ul>
                                    <li>
                                        <Link to="/careers">
                                            <i className="fa-solid fa-angle-right"></i>
                                            CAREERS</Link>
                                    </li>
                                    <li>
                                        <Link to="/projects">
                                            <i className="fa-solid fa-angle-right"></i>
                                            PROJECTS</Link>
                                    </li>
                                    <li>
                                        <Link to="/contactus">
                                            <i className="fa-solid fa-angle-right"></i>
                                            CONTACT US</Link>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <div className="footer-newsletter">
                                <h2 className="title">Newsletter</h2>
                                <p>Signup for our latest news & articles. We won’t give you spam mails.</p>

                                <form action="#">
                                    <div className="input-box">
                                        <input type="text" placeholder="Email Address" />
                                        <button>
                                            <i className="fa-solid fa-paper-plane"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <div className="contact_details">
                                <div className="contact-info">
                                    <h2 className="title">Contact</h2>
                                    <ul>
                                        <li>
                                            <i className="fa-solid fa-phone"></i>
                                            <span>1.574.264.8784</span>
                                        </li> 
                                        <li>
                                            <i className="fa-solid fa-envelope"></i>
                                            <span>contact@aspiretechnologiez.com</span>
                                        </li> 
                                        <li>
                                            <i className="fa-solid fa-location-pin"></i>
                                            <span>12607 Bear Creek Ter, <br /> Beltsville, MD 20705 USA</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    </Container>
                </div>
            </footer>

            <CopyRight />
        </Fragment>
    );
};

export default Footer;