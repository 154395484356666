import React from 'react';
import { Helmet } from 'react-helmet';

const MetaData = ({ title, description }) => {
    return (
        <Helmet>
            <title>{`${title} | Aspire Technologiez `}</title>
            <meta name="description" content={`${description} | Aspire Technologiez `} />
        </Helmet>
    );
};

export default MetaData;