import { Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import './TopNav.scss';

const TopNav = () => {
    return (
        <div className="top_nav">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <div className="top_nav_link top_contact_info">
                        <ul>
                            <li>
                                <Link to="/">
                                    <i className="fa-solid fa-envelope"></i>
                                    [email protected]
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <i className="fa-solid fa-phone"></i>
                                    574-264-8784
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <div className="top_nav_link social_icon">
                        <ul>
                            <li>
                                <Link to="/">
                                    <i className="fa-brands fa-facebook-f"></i>
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <i className="fa-brands fa-twitter"></i>
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <i className="fa-brands fa-behance"></i>
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <i className="fa-brands fa-linkedin-in"></i>
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <i className="fa-brands fa-youtube"></i>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Grid>

            </Grid>
        </div>
    );
};

export default TopNav;