import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/common/Footer/Footer';
import Navbar from '../components/common/Navbar/Navbar';
import TopNav from '../components/common/Navbar/TopNav/TopNav';
import MetaData from '../utils/MetaData';
import './../scss/style.scss';

const NotFound = () => {

    const navigate = useNavigate();

    return (
        <Fragment>
            <MetaData title="Page not found" />
            <TopNav />
            <Navbar />

            <div className="page-not-found py-100">
                <button onClick={() => navigate('/')} className="main-btn border-1px">Back to Home</button>
            </div>

            <Footer />
        </Fragment>
    );
};

export default NotFound;